@import "_app.scss";

/*=================== Normal CSS ========================*/
html,
body {
  height: 100%;
  min-width: 280px;
  padding: 0;
}

/*=================== RESET ============================*/

/*
OVERRIDES
---------
Fix a few issues from boostrap.
*/
h1, h2, h3, h4, h5, h6 {
  text-rendering: auto;
}

.lt-ie8 ul.inline > li,
.lt-ie8 ol.inline > li {
  display: inline;
  zoom: 1;
}

.lt-ie8 .nav > li > a:hover {
  cursor: pointer;
}

ul.thumbnails li.span3:nth-child(4n + 1) {
  clear: left;
}

ul.thumbnails li.span4:nth-child(3n + 1) {
  clear: left;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  -webkit-transition: border linear 0s;
  -moz-transition: border linear 0s;
  -o-transition: border linear 0s;
  transition: border linear 0s;
  -webkit-transition: border-color linear 0.2s;
  -moz-transition: border-color linear 0.2s;
  -o-transition: border-color linear 0.2s;
  transition: border-color linear 0.2s;
}

/*
HELPERS
-------
Useful classes for dealing with things that happen fairly often.
*/
.strtoupper {
  text-transform: uppercase;
}

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px;
}

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 100%;
}

/*======================================================*/

#wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  overflow: auto;

  /*sorts out bottom margin on last child*/

  background-image: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* FF3.6+ */
  background-image: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0, rgba(255, 255, 255, 0.4)), color-stop(70%, rgba(255, 255, 255, 0)));

  /* Chrome,Safari4+ */
  background-image: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* Chrome10+,Safari5.1+ */
  background-image: -o-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 70%);

  /* Opera 12+ */
  background-image: -ms-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 70%);

  /* IE10+ */
  background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* W3C */

  background-size: 600px 500px;
  background-repeat: no-repeat;
  background-position: center 0;
  padding: 0 20px;
}

.logo {
  height: 66px;
  margin-bottom: 20px;
  display: block;
  background-size: contain;
}

.header,
.content,
.footer {
  margin-bottom: 10px;
}

.header a,
.header a:hover,
.header a:focus,
.footer a,
.footer a:hover,
.footer a:focus {
  color: #fff;
}

a:hover,
a:focus {
  text-decoration: none;
}

.header {
  padding-top: 20px;
}

.icon-home {
  background: image-url("16px_home.png") 0 0;
  width: 16px;
  height: 16px;
  margin-top: 0;
  line-height: 16px;
}

.footer ul.inline > li {
  padding-left: 20px;
  padding-right: 20px;
}

.footer a {
  font-size: 12px;
}

.wrappedContent {
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 18px #424242;
  box-shadow: 0 0 18px #424242;
  padding: 30px;
}

.fixedMediumWidth {
  width: 570px;
  margin-left: auto;
  margin-right: auto;
}

.content h2 {
  font-family: "Myriad Pro", Calibri, Arial, Helvetica, "sans-serif", serif;
  font-weight: 100;
}

.content h6 {
  color: #7E7E80;
  font-weight: 100;
  font-size: 14px;
}

@media (max-width: 610px) {
  .fixedMediumWidth {
    width: auto;
  }

  .footer ul.inline > li {
    display: block;
  }

  .form-horizontal .control-label,
  .form-horizontal .controls,
  .header ul.inline {
    text-align: center;
  }
}

.form-horizontal .form-inline .controls {
  width: 220px;
}

.hidden-el {
  display: block !important;
  position: absolute !important;
  left: -9999em !important;
}

#wrap {
  min-height: 100%;
  height: 100%;
  overflow: auto;

  /*sorts out bottom margin on last child*/

  background-image: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* FF3.6+ */
  background-image: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0, rgba(255, 255, 255, 0.4)), color-stop(70%, rgba(255, 255, 255, 0)));

  /* Chrome,Safari4+ */
  background-image: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* Chrome10+,Safari5.1+ */
  background-image: -o-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 70%);

  /* Opera 12+ */
  background-image: -ms-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 70%);

  /* IE10+ */
  background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.4) 0, rgba(255, 255, 255, 0) 70%);

  /* W3C */

  background-size: 600px 500px;
  background-repeat: no-repeat;
  background-position: center 0;
  padding: 0 20px;
}

.header,
.content,
.footer {
  margin-bottom: 10px;
}

.header a,
.header a:hover,
.header a:focus,
.footer a,
.footer a:hover,
.footer a:focus {
  color: #fff;
}

a:hover,
a:focus {
  text-decoration: none;
}

.header {
  padding-top: 20px;
}


.footer ul.inline > li {
  padding-left: 20px;
  padding-right: 20px;
}

.footer a {
  font-size: 12px;
}

.wrappedContent {
  background-color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 18px #424242;
  box-shadow: 0 0 18px #424242;
  padding: 30px;
}

.fixedMediumWidth {
  width: 570px;
  margin-left: auto;
  margin-right: auto;
}

.content h2 {
  font-family: "Myriad Pro", Calibri, Arial, Helvetica, "sans-serif", serif;
  font-weight: 100;
}

.content h6 {
  color: #7E7E80;
  font-weight: 100;
  font-size: 14px;
}

@media (max-width: 610px) {
  .fixedMediumWidth {
    width: auto;
  }

  .footer ul.inline > li {
    display: block;
  }

  .form-horizontal .control-label,
  .form-horizontal .controls,
  .header ul.inline {
    text-align: center;
  }
}

.form-horizontal .form-inline .controls {
  width: 220px;
}

.hidden-el {
  display: block !important;
  position: absolute !important;
  left: -9999em !important;
}

/* Login Panel - Create account */

.panel {
  width:100%;
}

form + .panel {
  padding-top:20px;
}

/**/
.panel .title {
  padding:0;
  border-top:1px solid #e3e3e3;
  margin:0;
  text-align:center;
  font-size:15px;
  color:#706c6c;
}
.panel .title > * {
  padding:5px 10px;
  position:relative;
  top:-20px;
  background:#fff;
}

/**/
.panel .heading {
  float:left;
}
.panel .heading + .btn {
  float:right;
  margin-top:-15px;
}

.heading {
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: normal;
}
/* END - Login Panel - Create account */