$baseImageurl: '/compiled/img/';

@function image-url($url) {
  $index: str-index($url, $baseImageurl);
  @if $index == true {

    $indexSlash: str-index('/', $url);
    @if $indexSlash == 1 {
      $trimmed: str-slice($url, 2, -1);
      @return url("#{$baseImageurl}#{$trimmed}");
    }
  }
  @return url("#{$baseImageurl}#{$url}");
}
